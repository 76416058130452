import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Set up a Secure FTP Server for your WQHD Camera using Docker",
  "path": "/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/",
  "dateChanged": "2022-05-25",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Set up a Secure FTP Server for your WQHD Camera using Docker' dateChanged='2022-05-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/' locationFR='/fr/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "set-up-a-secure-ftp-server-for-your-wqhd-camera-using-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#set-up-a-secure-ftp-server-for-your-wqhd-camera-using-docker",
        "aria-label": "set up a secure ftp server for your wqhd camera using docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a Secure FTP Server for your WQHD Camera using Docker`}</h1>
    <p><strong parentName="p">{`Update`}</strong>{`: This tutorial works with both `}<strong parentName="p">{`Active`}</strong>{` (`}<strong parentName="p">{`PORT Mode`}</strong>{`) and `}<strong parentName="p">{`Passive`}</strong>{` (`}<strong parentName="p">{`PASV Mode`}</strong>{`) uploads. If you only need `}<strong parentName="p">{`Port Mode`}</strong>{` please read our tutorial `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`Set up an FTP Server for your WQHD Camera using Docker`}</a>{`.`}</p>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The simplest way would be to use your `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`Internet Router as FTP Server`}</a>{`. If you want to use a Linux Server like an Raspberry Pi we recommend using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/"
      }}>{`sFTP Service`}</a>{` instead of FTP or FTPS. But of course you can also set up your own FTP server instead. I would recommend building an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` Docker image for this task.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparing-the-docker-image"
        }}>{`Preparing the Docker Image`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-login"
            }}>{`FTP User Login`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-configuration"
            }}>{`FTP User Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#server-configuration"
            }}>{`Server Configuration`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#enable-tls-encryption"
        }}>{`Enable TLS Encryption`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#generate-a-private-key-and-certificate"
            }}>{`Generate a Private Key and Certificate`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#add-the-certificate-and-key-to-your-configuration-file"
            }}>{`Add the Certificate and Key to your Configuration File`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#copy-certificate-and-key-into-your-docker-image"
            }}>{`Copy Certificate and Key into your Docker Image`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#starting-the-docker-container"
        }}>{`Starting the Docker Container`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#build-the-image"
            }}>{`Build the Image`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-the-container"
            }}>{`Run the Container`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-an-instar-ip-camera"
        }}>{`Connecting an INSTAR IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#filezille-client"
            }}>{`Filezille Client`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wqhd-cameras"
            }}>{`WQHD Cameras`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "preparing-the-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-the-docker-image",
        "aria-label": "preparing the docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing the Docker Image`}</h2>
    <p>{`Start by creating a work directory and your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`, e.g. :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /opt/vsftpd
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` Dockerfile`}</code></pre></div>
    <p>{`And add the following content. Make sure port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`20`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`21`}</code>{` as well as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4242`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`4243`}</code>{`, are being forwarded to the server. We will need these extra ports for the passive mode - set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pasv_min_port`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pasv_max_port`}</code>{` accordingly in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd.conf`}</code>{` configuration file below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Dockerfile for vsftpd on CentOS7`}</span>{`
FROM centos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span>{`

MAINTAINER m.polinowski@instar.com

RUN yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y update; yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y install which vsftpd net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`tools vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`sysvinit; yum clean all

COPY vusers.txt /etc/vsftpd/
RUN db_load `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`T `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`t hash `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f /etc/vsftpd/vusers.txt /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db; rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`v /etc/vsftpd/vusers.txt; \\
	chmod 600 /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db
COPY vsftpd.conf /etc/vsftpd/
COPY vsftpd.virtual /etc/pam.d/
RUN mkdir `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p /home/vftp/ftpuser; chown `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`R ftp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ftp /home/vftp

EXPOSE 20 21 4242 4243

CMD `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/usr/sbin/vsftpd"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-obackground=NO"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`This `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{` will take the CentOS as a base image - this can be replaced by any flavour of Enterprise Linux. The next step installs the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` service and creates the FTP User account for us. After that we need to copy the following configuration files into the image - all of the need to be created in the same directory where we placed our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`:`}</p>
    <h3 {...{
      "id": "ftp-user-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-login",
        "aria-label": "ftp user login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Login`}</h3>
    <p><em parentName="p">{`vusers.txt`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ftpuser
mypassword`}</code></pre></div>
    <p>{`This is the user we will have to use to connect to the FTP server - change both the username and password according to your needs.`}</p>
    <h3 {...{
      "id": "ftp-user-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-configuration",
        "aria-label": "ftp user configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.virtual`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#%PAM-1.0`}</span>{`
auth       required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
account    required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
session    required     pam_loginuid.so`}</code></pre></div>
    <h3 {...{
      "id": "server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-configuration",
        "aria-label": "server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ftpd_banner`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Welcome to the INSTAR FTP service.
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Enforce user logins`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`anonymous_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`guest_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Local linux users are allowed to log in`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make sure PORT transfer connections originate from port 20`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`connect_from_port_20`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Enable PASV mode on ports 4242-4243`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_addr_resolve`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_min_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4242`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_max_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4243`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`virtual_use_local_privs`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FTP users can create, delete, rename and save files`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`write_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Default umask for local users`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_umask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`022
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Add user`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pam_service_name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`vsftpd.virtual
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user_sub_token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_root`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/home/vftp/`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`chroot_local_user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`allow_writeable_chroot`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`hide_ids`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Set network interface`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`listen`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`listen_ipv6`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Activate logging of uploads/downloads.`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_std_format`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# log file in standard ftpd xferlog format`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/var/log/vsftpd.log`}</code></pre></div>
    <h2 {...{
      "id": "enable-tls-encryption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#enable-tls-encryption",
        "aria-label": "enable tls encryption permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enable TLS Encryption`}</h2>
    <p>{`Starting from the standard configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd.conf`}</code>{` from the previous step we now need to add a TLS certificate to our server. If your server runs on a public server you can use a service like Let's Encrypt / Certbot to generated a valid certificate for the domain name of your server. You can use this certificate for your Web as well as your FTP server. Otherwise you can follow our tutorial on how to `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/CA_Certificate/#take-matters-into-your-own-hands"
      }}>{`generate your own CA certificate`}</a>{`. This tutorial goes through the steps of generating a valid CA cert for a local domain (provided by an AVM Fritzbox Home Internet Router) and put it to use with an `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Mosquitto_2.0_with_CA_Certificate/"
      }}>{`external MQTT Broker`}</a>{`. The same certificate can be used here as well.`}</p>
    <p>{`But in the following steps we will simply generate a self-signed certificate that can be used with an local IP address (instead of a domain name). Of course, this means that we will have to activate the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`insecure`}</code>{` mode in our cameras FTP client. This does not mean that the encryption will be weaker, but the camera will not try to verify if your personal FTP server really is the server the TLS certificate was issued to.`}</p>
    <h3 {...{
      "id": "generate-a-private-key-and-certificate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#generate-a-private-key-and-certificate",
        "aria-label": "generate a private key and certificate permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generate a Private Key and Certificate`}</h3>
    <p>{`Start by creating a private key and a TLS certificate with `}<a parentName="p" {...{
        "href": "https://www.openssl.org/"
      }}>{`Openssl`}</a>{`. To generate a private key, run:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/vsftpd/tls
openssl genrsa -out /opt/vsftpd/vsftpd.key`}</code></pre></div>
    <p>{`Next, generate a certificate signing request with the command below. You will be asked several questions during this process. Since we are not going to verify the resulting certificate, you can leave most of those fields empty. Just add your FTP servers local IP address when asked for the FQDN (fully qualified domain name), e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl req -new -key /opt/vsftpd/vsftpd.key -out /opt/vsftpd/vsftpd.csr

You are about to be asked to enter information that will be incorporated
into your certificate request.
What you are about to enter is what is called a Distinguished Name or a DN.
There are quite a few fields but you can leave some blank
For some fields there will be a default value,
If you enter `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'.'`}</span>{`, the field will be left blank.
-----
Country Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` letter code`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`AU`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
State or Province Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`full name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Some-State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Locality Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, city`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Organization Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, company`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`Internet Widgits Pty Ltd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Organizational Unit Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`eg, section`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.
Common Name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e.g. server FQDN or YOUR name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:192.168.2.111
Email Address `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:.

Please enter the following `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'extra'`}</span>{` attributes
to be sent with your certificate request
A challenge password `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:
An optional company name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:`}</code></pre></div>
    <p>{`Now, generate and sign the certificate which will be valid for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`397 days`}</code>{` (the maximum allowed length = 13 months)  as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`openssl x509 -req -days `}<span parentName="code" {...{
            "className": "token number"
          }}>{`397`}</span>{` -in /opt/vsftpd/vsftpd.csr -signkey /opt/vsftpd/vsftpd.key -out /opt/vsftpd/vsftpd.pem`}</code></pre></div>
    <p>{`And make sure that Docker will be able to use these new files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`755`}</span>{` /opt/vsftpd/*`}</code></pre></div>
    <h3 {...{
      "id": "add-the-certificate-and-key-to-your-configuration-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-the-certificate-and-key-to-your-configuration-file",
        "aria-label": "add the certificate and key to your configuration file permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add the Certificate and Key to your Configuration File`}</h3>
    <p><em parentName="p">{`vsftpd.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ftpd_banner`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`Welcome to the INSTAR FTP service.
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Enforce user logins`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`anonymous_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`guest_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Local linux users are allowed to log in`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make sure PORT transfer connections originate from port 20`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`connect_from_port_20`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Enable PASV mode on ports 4242-4243`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_addr_resolve`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_min_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4242`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pasv_max_port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4243`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`virtual_use_local_privs`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FTP users can create, delete, rename and save files`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`write_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Default umask for local users`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_umask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`022
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Add user`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pam_service_name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`vsftpd.virtual
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user_sub_token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_root`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/home/vftp/`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`chroot_local_user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`allow_writeable_chroot`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`hide_ids`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Set network interface`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`listen`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`listen_ipv6`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Add encryption`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`rsa_cert_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd.pem
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`rsa_private_key_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd.key
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ssl_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`allow_anon_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`force_local_data_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`force_local_logins_ssl`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ssl_tlsv1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ssl_sslv2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ssl_sslv3`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`require_ssl_reuse`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`ssl_ciphers`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`HIGH
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Activate logging of uploads/downloads.`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_std_format`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# log file in standard ftpd xferlog format`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/var/log/vsftpd.log`}</code></pre></div>
    <h3 {...{
      "id": "copy-certificate-and-key-into-your-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#copy-certificate-and-key-into-your-docker-image",
        "aria-label": "copy certificate and key into your docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Copy Certificate and Key into your Docker Image`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Dockerfile for vsftpd on CentOS7`}</span>{`
FROM centos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span>{`

MAINTAINER m.polinowski@instar.com

RUN yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y update; yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y install which vsftpd net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`tools vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`sysvinit; yum clean all

COPY vusers.txt /etc/vsftpd/
RUN db_load `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`T `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`t hash `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f /etc/vsftpd/vusers.txt /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db; rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`v /etc/vsftpd/vusers.txt; \\
	chmod 600 /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db
COPY vsftpd.conf /etc/vsftpd/
COPY vsftpd.virtual /etc/pam.d/
COPY vsftpd.pem /etc/vsftpd/
COPY vsftpd.key /etc/vsftpd/
RUN mkdir `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p /home/vftp/ftpuser; chown `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`R ftp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ftp /home/vftp

EXPOSE 20 21 4242 4243

CMD `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/usr/sbin/vsftpd"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-obackground=NO"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <h2 {...{
      "id": "starting-the-docker-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starting-the-docker-container",
        "aria-label": "starting the docker container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting the Docker Container`}</h2>
    <h3 {...{
      "id": "build-the-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#build-the-image",
        "aria-label": "build the image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build the Image`}</h3>
    <p>{`With those 4 files in place we are now ready to build our Docker image:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker build -t vsftpd_tls `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <h3 {...{
      "id": "run-the-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-the-container",
        "aria-label": "run the container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run the Container`}</h3>
    <p>{`Make sure that the FTP user directory exists and can be written to by your Docker user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`755`}</span>{` /opt/vsftpd/ftpuser/`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Make sure that the home directory `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/opt/vsftpd/ftpuser`}</code>{` is set to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`777`}</code>{` to prevent the FTP write error `}<strong parentName="p">{`ftp 550 Create directory operation failed.`}</strong></p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --name vsftpd `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/vsftpd/ftpuser/:/home/vftp/ftpuser/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    vsftpd_tls:latest`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      